// outermost wrapper around telegram
.telegram-vaðmál{
  $aspect-ratio: 8 / 6.5;
  $width: 700px;
  $height: $width / $aspect-ratio;
  
  height: $height;
  position: relative;
  width: $width;
}
