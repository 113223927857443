// tape content
.tape-content {
  $font-size: 23px;
  $font-color: #8800AA;

  -webkit-text-stroke: 1px #48006B;
  color: $font-color;
  display: inline-block;
  font-family: Telegram, Courier, fixed-width;
  font-size: $font-size;
  margin: 0;
  opacity: 0.84;
  padding: 0 $font-size / 3;
  position: relative;
  z-index: 1;
}