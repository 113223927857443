// Container for template telegram and individual panels
.telegram-stað-þing{  
  $horizontal-fold: 0.50;
  $vertical-fold: 0.76;
  $horizontal-fold-percent: percentage($horizontal-fold);
  $vertical-fold-percent: percentage($vertical-fold);
  $duration: 0.5s;

  $panel-1-fold-transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
  $panel-1-unfold-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  $panel-2-fold-transform: rotateX(-95deg) rotateY(0deg) rotateZ(0deg);
  $panel-2-unfold-transform: $panel-1-unfold-transform;
  $þing-shift-transform-step-1: translateX(0) translateY($horizontal-fold-percent / 2) translateZ(0);
  $þing-shift-transform-step-2: translateX(percentage((1 - $vertical-fold) / 2 )) translateY($horizontal-fold-percent / 2) translateZ(0);
  $þing-unshift-transform: translateX(0) transformY(0) transformZ(0);

  animation-duration: $duration;
  animation-fill-mode: forwards; 
  float: left;
  perspective: 1000px;
  position: relative;
  transition: $duration;


  // Telegram wrapper
  .telegram-vaðmál{
    position: relative;
  }

  // Telegram template
  .telegram-stað-telegram-template{
    position: relative;
    visibility: hidden;
  }

  // Telegram panel styling
  .telegram-stað-telegram-panel-0,
  .telegram-stað-telegram-panel-1,
  .telegram-stað-telegram-panel-2 {
    backface-visibility: hidden;
    transform-origin: top left;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
  }

  .telegram-stað-telegram-panel-1,
  .telegram-stað-telegram-panel-2{
    animation-duration: $duration;
    animation-fill-mode: forwards;
  }

  // Top left panel
  .telegram-stað-telegram-panel-0{
    height: $horizontal-fold-percent;
    width: $vertical-fold-percent;
    z-index: 2;
  }

  // Top right panel
  .telegram-stað-telegram-panel-1{
    height: $horizontal-fold-percent;
    left: $vertical-fold-percent;
    width: percentage(1 - $vertical-fold);
    z-index: 1;

    > * {
      right: percentage(1 / (1 - $vertical-fold) - 1);
    }

  }

  // Bottom panel
  .telegram-stað-telegram-panel-2{
    height: $horizontal-fold-percent;
    top: $horizontal-fold-percent;
    width: 100%;
    z-index: 0;

    > * {
      bottom: percentage(1 / (1 - $horizontal-fold) - 1);
    }
  }

  // Fold
  .telegram-stað-vaðmál.folded:not(.pre-folded) &{
    animation-name: shift;
    .telegram-stað-telegram-panel-1{
      transform: $panel-1-unfold-transform;
      animation-duration: $duration / 2;
      animation-delay: $duration / 2;
      animation-name: fold-panel-1;
    }
    .telegram-stað-telegram-panel-2{
      animation-duration: $duration / 2;
      animation-name: fold-panel-2;
    }
  }

  // Already folded
  .telegram-stað-vaðmál.pre-folded:not(.unfolded) & {
    transform: $þing-shift-transform-step-2;
    .telegram-stað-telegram-panel-1{
      transform: $panel-1-fold-transform;
    }
    .telegram-stað-telegram-panel-2{
      transform: $panel-2-fold-transform;
    }
  }

  // Unfold
  .telegram-stað-vaðmál.unfolded &{
    animation-name: unshift;
    .telegram-stað-telegram-panel-1{
      transform: $panel-1-fold-transform;
      animation-duration: $duration / 2;
      animation-name: unfold-panel-1;
    }
    .telegram-stað-telegram-panel-2{
      transform: $panel-2-fold-transform;
      animation-duration: $duration / 2;
      animation-delay: $duration / 2;
      animation-name: unfold-panel-2;
    }
  }

  @keyframes fold-panel-1{
    0%    { transform: $panel-1-unfold-transform; }
    100%  { transform: $panel-1-fold-transform; }
  }

  @keyframes fold-panel-2{
    0%    { transform: $panel-2-unfold-transform; }
    100%  { transform: $panel-2-fold-transform; }
  }

  @keyframes unfold-panel-1{
    0%    { transform: $panel-1-fold-transform; }
    100%  { transform: $panel-1-unfold-transform; }
  }

  @keyframes unfold-panel-2{
    0%    { transform: $panel-2-fold-transform; }
    100%  { transform: $panel-2-unfold-transform; }
  }


  // Translate content during fold animation
  @keyframes shift {
    0%{
      transform: $þing-unshift-transform;
    }
    50%{
      transform: $þing-shift-transform-step-1;
    }
    100%{
      transform: $þing-shift-transform-step-2;
    }
  }

  // Untranslate content during unfold animation 
  @keyframes unshift {
    0%{
      transform: $þing-shift-transform-step-2;
    }
    50%{
      transform: $þing-shift-transform-step-1;
    }
    100%{
      transform: $þing-unshift-transform;
    }
  }
}


