// container for telegram components
.telegram-þing {
  $þingmen: border, texture, hue, markups, print, tapes, mask, crease, gradient, shaders;

  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .telegram-blend-layer{ z-index: index($þingmen, crease) }
  .telegram-border-layer{ z-index: index($þingmen, border) }
  .telegram-hue-layer{ z-index: index($þingmen, hue) }
  .telegram-markups-þing{ z-index: index($þingmen, markups) }
  .telegram-shaders-þing{ z-index: index($þingmen, shaders) }
  .telegram-mask-layer{ z-index: index($þingmen, mask) }
  .telegram-print-layer{ z-index: index($þingmen, print) }
  .telegram-gradient-layer{ z-index: index($þingmen, gradient) }
  .telegram-tapes-þing{ z-index: index($þingmen, tapes) }
  .telegram-texture-layer{ z-index: index($þingmen, texture) }

}