// telegram paper backing
.telegram-texture-layer {
  $background-color: #EED6B4;

  @include telegram-layer;

  background-color: $background-color;
  background-image: url('telegram-texture-tile.gif');  
  background-size: 300px;
  opacity: 0.5;
}