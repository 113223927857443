// container for timestamp components
.timestamp-content {
  $color: #312C28;

  span{
    color: $color;
    font-family: 'Timestamp', 'Telegram', Courier, fixed-width;
    position: relative;
    text-shadow: 0.2px 0.5px 0 black;

    &:first-child, &:nth-child(2){
      opacity: 0.8;
      top: -1px;
    }

    &:last-child{
      top: -1px;
    }

  }
}