// tape backing
.tape-backing {
  $border-width: 2px;
  $bottom-border: none;
  $shadow-opacity: 0.32;
  $top-border: 0.5px solid rgba(255,255,255,0.32);
  $z-index: 0;

  background: #F2EAD1;
  background-image: url(tape-texture.gif);
  background-size: auto 50%;
  border-bottom: $bottom-border;
  border-top: $top-border;
  box-shadow: 0px 0.5px 0.5px rgba(0,0,0, $shadow-opacity);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $z-index - 1;

  &:before, &:after{
    background-image: url(tape-border.gif);
    background-size: cover;
    border-bottom: $bottom-border;
    border-top: $top-border;
    content: "";
    height: 100%;
    position: absolute;
    top: -1px;
    width: $border-width;
  }

  &:before{
    left: -$border-width;
  }

  &:after{
    background-position: right;
    right: -$border-width;
  }

}