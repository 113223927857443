// telegram paper backing
.telegram-print-layer {

  @include telegram-layer;

  background-image: url('telegram-layer-print-180206.gif');
  background-size: 99%;
  background-position: center;
  mix-blend-mode: multiply;
  opacity: 0.7;


  .telegram-design-171108 &{
    background-image: url('telegram-layer-print-171108.gif');
  }
  .telegram-design-171212 &{
    background-image: url('telegram-layer-print-171212.gif');
  }
  .telegram-design-171213 &{
    background-image: url('telegram-layer-print-171213.gif');
  }
  .telegram-design-171222 &{
    background-image: url('telegram-layer-print-171222.gif');
  }
  .telegram-design-171223 &{
    background-image: url('telegram-layer-print-171223.gif');
  }
  .telegram-design-180206 &{
    background-image: url('telegram-layer-print-180206.gif');
  }
  .telegram-design-180226 &{
    background-image: url('telegram-layer-print-180226.gif');
  }
  .telegram-design-180227 &{
    background-image: url('telegram-layer-print-180227.gif');
    mix-blend-mode: normal;
    opacity: 1;
  }
}