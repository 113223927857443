// telegram border
.telegram-tapes-þing {
  $indent: 3%;
  $hsep: 2%;

  padding-left: 10%;
  padding-top: 30%;
  position: relative;

  .telegram-design-171222 &{
    padding-left: 15%;
  }
  .telegram-design-171223 &{
    padding-left: 15%;
  }
  .telegram-design-180226 & {
    padding-left: 18%;
    padding-top: 29%;
  }

  // Second Line
  >:nth-child(2){
    margin-left: $indent;
    margin-bottom: $hsep;
  }


  .telegram-vaðmál.autoindent & >:last-child:not(:first-child):not(:nth-child(2)):not(:nth-child(3)){
    margin-top: $hsep;
    margin-left: $indent;
  }

}