// shading overlay 
.telegram-shaders-þing{
  $dark: black;
  $light: white;
  $hfold: 0.50;
  $vfold: 0.76;
  $gutter: 0.25;
  $top-height: percentage($hfold);
  $bottom-height: percentage(1 - $hfold);
  $left-width: percentage($vfold);
  $right-width: percentage(1 - $vfold);
  $opacity: 0.15;
  $top-opacity: 0.32;
  $margin: 0.25%;


  @include telegram-layer;
  @mixin shader-background($dir){
    background: linear-gradient(to $dir, $dark, $light);
  }
  @mixin illuminate($vdir, $hdir){
    @include shader-background($vdir);
    &:after{
      @include shader-background($hdir);
    }
  }

  mix-blend-mode: multiply;
  opacity: $opacity;
  padding: 0;
  margin: $margin;

  > * {
    @include illuminate(bottom, left);

    display: block;
    position: absolute;
    
    &:after{
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  :first-child{
    opacity: $top-opacity;
    height: $top-height - $gutter;
    width: $left-width - $gutter;
  }

  :nth-child(2){
    opacity: $top-opacity;
    height: $top-height - $gutter;
    left: $left-width + $gutter;
    width: $right-width - $gutter;
  }

  :nth-child(3){
    height: $bottom-height - $gutter;
    top: $top-height + $gutter;
    width: $left-width - $gutter;
  }

  :nth-child(4){
    height: $bottom-height - $gutter;
    left: $left-width + $gutter;
    top: $top-height + $gutter;
    width: $right-width - $gutter;
  }

}
