// telegram paper backing
.telegram-markups-þing {

  @include telegram-layer;

  > * {
    position: absolute;
  }

  .telegram-timestamp {
    transform: rotate(-1deg);
    top: 25%;
    right: 10%;
  }

  .telegram-design-171213 &{
    .telegram-timestamp{
      right: 8%;
      top: 33%;
      transform: rotate(2deg);
    }
  }

  .telegram-design-171212 &{
    .telegram-timestamp{
      right: 8%;
      top: 33%;
      transform: rotate(2deg);
    }
  }

  .telegram-design-180226 &{
    .telegram-timestamp{
      left: 20%;
      top: 18%;
      transform: rotate(2deg);
    }
  }


}